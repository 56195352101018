document.addEventListener('DOMContentLoaded', function () {
    const bodyElem = document.getElementsByTagName('body')[0]
    const navbarElem = document.getElementsByClassName('navbar')[0]
    const navLinkElements = navbarElem.getElementsByClassName('nav-link')

    document.addEventListener('scroll', function () {
        let class_to_remove = ''
        let class_to_add = ''

        if (window.scrollY > 100) {
            bodyElem.classList.add('scrolled')

            class_to_remove = 'text-white'
            class_to_add = 'text-primary'
        } else {
            bodyElem.classList.remove('scrolled')

            class_to_remove = 'text-primary'
            class_to_add = 'text-white'
        }

        for (let navLinkElem of navLinkElements) {
            navLinkElem.classList.remove(class_to_remove)
            navLinkElem.classList.add(class_to_add)
        }
    })
})
